import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/layout/Layout"
import MetaData from "../components/seo/MetaData"
import WideContainer from "../components/layout/WideContainer"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../components/layout/Container"

interface Props {
  location: any
}

const NotFound: React.FunctionComponent<PageProps> = ({ location }: Props) => (
  <Layout>
    <MetaData
      title="Page not found"
      description="We weren't able to find the page you requested."
      pageUrl={location.pathname}
    />
    <div className="bg-white">
      <WideContainer className="pt-4 text-center">
        <h1>We couldn't find your page</h1>
        <p className="mt-0">
          Navigate to the front page to start your search again.
        </p>
        <Container center={true}>
          <StaticImage
            src="../images/404.png"
            alt="Page not found"
            placeholder="blurred"
            className="w-auto mr-0 ml-0 md:mr-4 md:ml-4"
          />
        </Container>
      </WideContainer>
    </div>
  </Layout>
)

export default NotFound
